const isObject = (item: any) => {
  return item && typeof item === "object" && !Array.isArray(item);
};

const deepDiff = (initial: Record<string, any>, data: Record<string, any>) => {
  const diff: Record<string, any> = {};

  Object.entries(initial).forEach(([key, value]) => {
    if (!isObject(value) || !isObject(data[key])) {
      const isArray = Array.isArray(value);
      if (
        (isArray && JSON.stringify(value) === JSON.stringify(data[key])) ||
        (!isArray && String(value) === String(data[key]))
      ) {
        return;
      }
      diff[key] = data[key];
      return;
    }

    const nestedDiff = deepDiff(value, data[key]);
    if (nestedDiff && !Object.keys(nestedDiff).length) return;
    diff[key] = nestedDiff;
  });

  Object.entries(data).forEach(([key, value]) => {
    if (key in initial) return;
    diff[key] = value;
  });

  return diff;
};

export default deepDiff;
