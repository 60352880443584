
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import { RouteSetting } from "@/modules/crudTable/store/crudTableStore";
import {
  clientLogFinIndex,
  LogFinIndexItem,
} from "@/modules/logFin/api/logFinIndex";
import LogFinReportFilter from "@/modules/salesOrder/components/LogFinReportFilter.vue";
import dayjs from "@/plugins/dayjs";
import ExportButton from "@/components/ExportButton.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<LogFinIndexItem> & { clientId: number; id: number }[];
  defaultFilters: Record<string, unknown>;
  exports: ExportType[];
  filterComponent: VueConstructor;
  stockFlowData: LogFinIndexItem[];
}

export default Vue.extend({
  name: "LogFinReportTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "dimProduct.articleNumber", sortable: false },
      { value: "dimProduct.productName", sortable: false },
      { value: "initialStockMonth", sortable: true },
      { value: "salesOrders", sortable: true },
      { value: "receivedAndReturns", sortable: false },
      { value: "correction", sortable: true },
      { value: "initialStockNextMonth", sortable: true },
    ],
    selected: [],
    exports: [],
    defaultFilters: {},
    stockFlowData: [],
    filterComponent: LogFinReportFilter,
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
    currentRouteSettings(): RouteSetting {
      return this.getRouteSetting(this.$route.name);
    },
    monthAndYearString() {
      const { month, year } = this.currentRouteSettings.pagination?.params;
      const backupYear = this.currentRouteSettings?.pagination?.params?.year;

      if (month && (year || backupYear)) {
        return dayjs(`${month}-01-${year || backupYear}`).format("MMM YYYY");
      }
      return year || backupYear;
    },
  },
  created() {
    this.setDefaultFilters();
  },
  watch: {
    client: {
      handler() {
        this.resetTable();
      },
      deep: true,
    },
  },
  methods: {
    selectItems(ids: number[]): void {
      if (!this.client?.id) throw "Selecting id's when clientId is not set"; //@TODO for now this function is only used when client is set

      this.selected = ids.map((id) => ({ id, clientId: this.client?.id })); //@TODO
    },
    async logFinIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientLogFinIndex(data, this.client?.id);
      this.stockFlowData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    resetTable(): void {
      (this.$refs.table as any).handleReset();
    },
    setDefaultFilters() {
      this.defaultFilters = {
        month: dayjs().month() + 1,
        year: dayjs().year(),
      };
    },
  },
});
